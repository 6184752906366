<template>
  <b-container id="saml-partner-new" class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>New SAML Partner</h3>
        <router-link :to="{ name: 'SamlCorePartnerList' }" class="d-flex justify-content-left align-items-center">
          <feather class="back-icon" type="arrow-left"></feather>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="6">
        <b-form-group
          label="Partner Key"
          description="Unique key to identify the Partner in Sword"
          label-for="partner-key"
          invalid-feedback="Invalid key"
          :state="keyValidationState">
          <b-form-input
            id="partner-key" type="text" placeholder="Enter Partner Key"
            :state="keyValidationState" v-model="partner.key"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <partner-configuration :value="partner.configuration"/>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="6">
        <b-form-group
          label="Partner Name"
          label-for="partner-name"
          description="Human friendly name of the Partner"
          invalid-feedback="Invalid name"
          :state="nameValidationState">
          <b-form-input
            id="partner-name" type="text"
            :state="nameValidationState"
            v-model="partner.name"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="4" class="d-flex justify-content-left align-items-center">
        <b-button variant="primary" @click="create" :disabled="creating || $v.$invalid">
          <b-spinner small v-if="creating"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { required, helpers } from 'vuelidate/lib/validators';
import PartnerConfiguration from '@/views/Saml/Core/Partners/components/PartnerConfiguration.vue';
import { create } from '@/api/saml/core/partners';

const partnerKeyValidator = helpers.regex('partnerKey', /^[a-z0-9_]{3,64}$/);

const partnerNameValidator = helpers.regex('partnerName', /^.{1,75}$/);

export default {
  name: 'SamlCorePartnerNew',
  components: {
    PartnerConfiguration,
  },
  data() {
    return {
      partner: {
        key: '',
        name: '',
        configuration: {},
      },
      creating: false,
    };
  },
  validations() {
    return {
      partner: {
        key: {
          required,
          partnerKeyValidator,
        },
        name: {
          required,
          partnerNameValidator,
        },
      },
    };
  },
  computed: {
    keyValidationState() {
      return !this.$v.partner.key.$invalid;
    },
    nameValidationState() {
      return !this.$v.partner.name.$invalid;
    },
  },
  methods: {
    create() {
      this.creating = true;
      create(this.partner)
        .then(() => {
          this.creating = false;
          this.$router.push({ name: 'SamlCorePartnerList' });
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to create partner: ${err}`);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
